/**
 * ocaNamesspace.js creates a safe namespace of OCA7559AC to be used for 
 * OurClubAdmin javascript. Any namespace clashes are logged using an ajax call
 * to /ajaxLogFailure.php.
 * 
 * @copyright (c) 2013-2019, Quidoxis Ltd
 */

// debug
// console.log(selectedPerson);
// console.log("seq: "+seq);
// debug

// create the root namespace and making sure we're not overwriting it
if (typeof OCA7559AC === "undefined") {
    var OCA7559AC = {};
}
else {
    // we don't expect this rootname to exist, so log a message if it does
    var ajaxData = {whatFailed: 'ocaNamespace.js', 
                    ajaxResponseText: 'OCA7559AC namespace clash'};
    $.ajax({
            type        : 'POST', 
            url         : '/ajaxLogFailure.php', // POST url
            data        : ajaxData, 
            dataType    : 'json', // type of data expected back from server
            encode      : true
    })
    .always(function() {
        var msg = 'We are sorry but we are unable to process your request at ' +
                  'the moment. Our support team has been notified and we '+
                  'will resolve the problem as soon as we can.';
        
        $('#alertBoxSuccess').html('').hide();
        $('#alertBoxError').html( msg ).show();
    });
}
 
// general purpose namespace method that will allow us to create new nested
// namespaces within OCA7559AC
OCA7559AC.createNS = function (namespace) {
    var nsparts = namespace.split(".");
    var parent = OCA7559AC;
 
    // we want to be able to include or exclude the root namespace 
    // So we strip it if it's in the namespace
    if (nsparts[0] === "OCA7559AC") {
        nsparts = nsparts.slice(1);
    }
 
    // loop through the parts and create 
    // a nested namespace if necessary
    for (var i = 0; i < nsparts.length; i++) {
        var partname = nsparts[i];
        // check if the current parent already has 
        // the namespace declared, if not create it
        if (typeof parent[partname] === "undefined") {
            parent[partname] = {};
        }
        // get a reference to the deepest element 
        // in the hierarchy so far
        parent = parent[partname];
    }
    // the parent is now completely constructed 
    // with empty namespaces and can be used.
    return parent;
};
