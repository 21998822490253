// Extremely very dirty hack to prevent IE from caching AJAX requests
$.ajaxSetup({
    cache: false
});


/**
 * ocaCommon.js creates the Oca7559AC.COMMON namespace and populates it with
 * generic javascript/jQuery functions that are used throughout OurClubAdmin
 * pages. These functions are all non-business related, and manage page
 * artifacts such as page messages, error fields, scrolling etc.
 * 
 * @copyright (c) 2013-2019, Quidoxis Ltd
 */

// debug
// console.log(selectedPerson);
// console.log("seq: "+seq);
// debug

// create the namespace
OCA7559AC.createNS('OCA7559AC.COMMON');





// 
//
// date handling functions
//
//
		
/*
 * ocaDateFunctions provides standard date-handling functions
 *
 * @author  Clive Grummett
 * @version 11 Feb 2019
 */
OCA7559AC.COMMON.ocaDateFunctions = function () {
    
    // define methods
    
    /**
     * Returns a Javascript Date from day, month and year parts if valid, 
     * returns false otherwise.
     * 
     * @param {type} year
     * @param {type} month
     * @param {type} day
     * @returns {Date|Boolean}
     */
    var makeDateFromParts = function(year,month,day) {
        // console.log("Making date from " + year + "," + month + "," + day);
        var intYear = parseInt(year);
        var intMonth = parseInt(month);
        var intDay = parseInt(day);
        var jsDate = false;
        if ((!isNaN(intYear) && !isNaN(intMonth) && !isNaN(intDay) )
                && (intYear > 0 && intMonth > 0 && intDay > 0)) {
            var dateText = year+'-'+month+'-'+day;
            // var jsDate = Date.parse(dateText);
            var jsDate = moment(dateText, 'YYYY-MM-DD');
//            if (Number.isNaN(jsDate) || jsDate === null) {
//            altered to support IE11
            if (jsDate === null || isNaN(jsDate)) {
                return false;
            } else {
                return jsDate;
            }
        } else {
            return jsDate;
        }   
    }
    
    
    var dateNowString = function () {
        var now = moment();
        // var dateNow = now.getFullYear()+'-'+(now.getMonth()+1)+'-'+now.getDate();
        var dateNow = now.format('YYYY-MM-DD');
        return dateNow;
    };  // end of dateNowString
    
    /*
     * inAgeRangeAtDate returns true if the person's age (derived from their 
     * date of birth) is in the specified age range at the specified date, 
     * and false if not.
     * 
     * @param {Date} dob      
     * @param {string} ageFrom  can be null
     * @param {string} ageTo    can be null
     * @param {string} checkDate
     * @returns {bool} inRange
     *
     * @author  Clive Grummett
     * @version 11 Feb 2019
     */
    var inAgeRangeAtDate = function (dob, ageFrom, ageTo, checkDate) {
        var inRange;
        if (dob == null && ageFrom == null && ageTo == null) {
            inRange = true;

        } else if (dob == null) {
            inRange = false;

        } else {
            var age = calcAgeAtDate(dob, checkDate);
            if ((ageFrom == null || age >= ageFrom) && (ageTo == null || age <= ageTo)) {
                inRange = true;
            } else {
                inRange = false;
            }
        }
        return inRange;
    };  // end of inAgeRangeAtDate
    
    /*
     * calcAgeAtDate calculates an age at a specified date from a date of birth.
     * If no date is specified, the today's date is used.
     * 
     * @param {Date} dateOfBirth  JS Date (moment)
     * @param {string} ageAtDate
     * @returns {int}  age
     *
     * @author  Clive Grummett
     * @version 11 Feb 2019
     */
    var calcAgeAtDate = function(dateOfBirth, ageAtDate) {

        var atDate = (ageAtDate == null) ? moment() : moment(ageAtDate, 'YYYY-MM-DD');
        
        if (dateOfBirth < atDate) {
            var age = atDate.diff(dateOfBirth, 'years');
        } else {
            var age = 0;
        }
        // console.info("DOB: " + dateOfBirth.format('YYYY-MM-DD') + ", At: " + atDate.format('YYYY-MM-DD') + ", Age: " + age);
        return age;
    };  // end of calcAgeAtDate
    
    // public api
    return {
        dateNowString: dateNowString,
        inAgeRangeAtDate: inAgeRangeAtDate,
        calcAgeAtDate: calcAgeAtDate,
        makeDateFromParts: makeDateFromParts
    };
};


// 
//
// Add functions for showing and hiding elements
//
//
		
/**
 * showHider shows or hides an element from a button
 * 
 * @param {object} elToggle    element which toggles the show/hide
 * @param {string} toggleTextWhenHidden  text to display when hidden
 * @param {string} toggleTextWhenShown   text to display when shown
 * @param {object} elTarget the target element to toggle visibility of
 * 
 * @author  Clive Grummett
 * @version 2 August 2016
 */
OCA7559AC.COMMON.showHider = function (
        elToggle, toggleTextWhenHidden, toggleTextWhenShown, elTarget) {

    if (!elTarget.is(':visible')) {
        elTarget.show('slow');
        elToggle.html(toggleTextWhenShown);
    } else {
        elTarget.hide('slow');
        elToggle.html(toggleTextWhenHidden);
    }
};	// end of showHider

// 
//
// 
//
//

/**
 * ocaErrorHandler manages system errors caught by javascript.
 */
OCA7559AC.COMMON.ocaErrorHandler = function (identifier) {
    // initialise the object
    var idPrefix = identifier + ': ';
    var messages = {
        systemError: 'We are sorry but we are unable to process your request ' +
                     'at the moment. Our support team has been notified and ' +
                     'we will resolve the problem as soon as we can.',
        fieldErrors: 'Please correct the highlighted fields.'
    };

    
    // define methods
    var log = function(whatFailed, logMsg) {
        var error = {
            whatFailed: idPrefix + whatFailed,
            ajaxResponseText: logMsg
        };
        $.ajax({
            type        : 'POST', 
            url         : '/ajaxLogFailure.php', // POST url
            data        : error, 
            dataType    : 'json', // type of data expected back from the server
            encode      : true,
            async       : false
        })
        .always(function() {
            return true;
        });
    };
    var hideModalMsgs = function () {
        $('#alertBoxSuccessModal').html('').hide();
        $('#alertBoxWarningModal').html('').hide();
        $('#alertBoxErrorModal').html('').hide();
    };
    var displayModalSuccessMsg = function(message) {
        $('#alertBoxSuccessModal').html( message ).show();
    };
    var displayModalWarningMsg = function(message) {
        $('#alertBoxWarningModal').html( message ).show();
    };
    var displayModalErrorMsg = function(message) {
        $('#alertBoxErrorModal').html( message ).show();
    };
    var displayModalMsgs = function(msgs) {
        if (msgs.success && msgs.success != '') {
            displayModalSuccessMsg(msgs.success);
        }
        if (msgs.warning && msgs.warning != '') {
            displayModalWarningMsg(msgs.warning);
        }
        if (msgs.error && msgs.error != '') {
            displayModalErrorMsg(msgs.error);
        }
    };
    var hidePageMsgs = function () {
        $('#alertBoxSuccess').html('').hide();
        $('#alertBoxWarning').html('').hide();
        $('#alertBoxError').html('').hide();
        $('#alertBoxSuccessAjax').html('').hide();
        $('#alertBoxWarningAjax').html('').hide();
        $('#alertBoxErrorAjax').html('').hide();
    };
    var displayPageSuccessMsg = function(message) {
        $('#alertBoxSuccessAjax').html( message ).show();
    };
    var displayPageWarningMsg = function(message) {
        $('#alertBoxWarningAjax').html( message ).show();
    };
    var displayPageErrorMsg = function(message) {
        $('#alertBoxErrorAjax').html( message ).show();
    };
    var displaySystemErrorMsg = function() {
        $('#alertBoxErrorAjax').html( getSystemErrorMsg() ).show();
    };
    var displayPageMsgs = function(msgs) {
        if (msgs.success && msgs.success != '') {
            displayPageSuccessMsg(msgs.success);
        }
        if (msgs.warning && msgs.warning != '') {
            displayPageWarningMsg(msgs.warning);
        }
        if (msgs.error && msgs.error != '') {
            displayPageErrorMsg(msgs.error);
        }
    };
    var getSystemErrorMsg = function() {
        return messages.systemError;
    };
    var getFieldErrorsMsg = function() {
        return messages.fieldErrors;
    };
    var getIdForFieldName = function(fldName) {
        var fldTemp = fldName.replace(/]/g, '');
        var fldId = fldTemp.replace(/\[/g, '-');
        return fldId;
    };
    var clearFieldErrors = function() {
        $('.formerror').empty();
        $('.formError').removeClass('error');
        $('input').removeClass('error');
        $('.formErrors').addClass('hide');
    };
    var clearFieldError = function(fieldId) {
        var sourceField = $('#'+fieldId);
        var errorField = $('#'+fieldId+'_error');
        sourceField.removeClass('error');
        errorField.removeClass('error');
        errorField.empty();
    };
    var displayFieldErrors = function(errors) {
        var fldId;
        $.each(errors, function(field, message) {
            fldId = getIdForFieldName(field);
            displayFieldError(fldId, message);
        });
    };
    var displayFieldError = function(fieldId, message) {
        var sourceField = $('#'+fieldId);
        var errorField = $('#'+fieldId+'_error');
        if (errorField.length) {
            errorField.html('<span class="formerror">'+message+'</span>');
            errorField.addClass('error');
        }
        sourceField.addClass('error');
    };
    
    /**
    * displayFormErrors displays form errors
    * 
    * @deprecated LEGACY FUNCTION FROM common.js
    * 
    * @param errors   object  in format {field1: error1, ..., fieldn: errorn}
    * @param scrollTo object  where page should scroll to when errors displayed
    * 
    * @author  Clive Grummett
    * @version 10 Oct 2016
    */
    var displayFormErrors = function(errors, scrollTo) {
	$.each(errors, function(field,message){
            displayFieldError(field, message);
	});
	$('.formErrors').html('Please correct the highlighted fields.');
        $('.formErrors').removeClass('hide');
        $("html, body").scrollTop(scrollTo.offset().top);        
    };
    
    /**
     * displayFormArrayErrors displays form errors
     * 
     * @deprecated LEGACY FUNCTION FROM common.js
     * 
     * @param errors   object  in format: 
     *                          {field_a: {index1: message1, ..., indexn: messagen}, 
     *                           ..., 
     *                           field_z: {index1: message1, ..., indexn: messagen}}
     * @param scrollTo object  where page should scroll to when errors displayed
     * 
     * @author  Clive Grummett
     * @version 10 Oct 2016
     */
    var displayFormArrayErrors = function(errors, scrollTo) {
            $.each(errors, function(field,error){
                $.each(error, function(idx,message){
                    displayFieldError(field+idx, message);
                });
            });
            $('.formErrors').html('Please correct the highlighted fields.');
        $('.formErrors').removeClass('hide');
        $("html, body").scrollTop(scrollTo.offset().top);
    };   // end of displayFormArrayErrors    
    
    function scrollToPageErrorMessage() {
        $("html, body").scrollTop($('.pageMessages').offset().top); 
    }
    // public api
    return {
        log: log,
        getSystemErrorMsg: getSystemErrorMsg,
        getFieldErrorsMsg: getFieldErrorsMsg,
        hideModalMsgs: hideModalMsgs,
        displayModalSuccessMsg: displayModalSuccessMsg,
        displayModalWarningMsg: displayModalWarningMsg,
        displayModalErrorMsg: displayModalErrorMsg,
        displayModalMsgs: displayModalMsgs,
        hidePageMsgs: hidePageMsgs,
        displayPageSuccessMsg: displayPageSuccessMsg,
        displayPageWarningMsg: displayPageWarningMsg,
        displayPageErrorMsg: displayPageErrorMsg,
        displaySystemErrorMsg: displaySystemErrorMsg,
        displayPageMsgs: displayPageMsgs,
        clearFieldErrors: clearFieldErrors,
        displayFieldErrors: displayFieldErrors,
        clearFieldError: clearFieldError,
        displayFieldError: displayFieldError,
        displayFormErrors: displayFormErrors,
        displayFormArrayErrors: displayFormArrayErrors,
        scrollToPageErrorMessage: scrollToPageErrorMessage
    };
};	// end of ocaErrorHandler

/**
 * ocaPage provides common management methods for a standard OurClubAdmin page.
 * 
 * @param {string} pgName    page name, e.g. 'sysman/update-question-scope'
 * @param {bool}   useForm   true if the page uses a standard main form
 * @param {bool}   useModal  true if the page uses a standard modal popup form
 */
OCA7559AC.COMMON.ocaPage = function (pgName, useForm, useModal) {	
    // initialise the object
    var common = OCA7559AC.COMMON;
    var pageName = pgName;
    var ocaErrorHandler = new common.ocaErrorHandler(pageName);
    var ocaHtml = new common.ocaHtml();
    var actionsEnabled = true;
    if (useForm) {
        var ocaForm = new common.ocaForm(ocaErrorHandler, ocaHtml);
    }
    if (useModal) {
        var ocaModal = new common.ocaModal(ocaErrorHandler, ocaHtml);
    }
    
    // hide any showHide panels
    $('.showHidePanel').hide();

    // add listener for showHide button
    $('.showHideButton').click( function(e){
        var elShowhideButton = $(this);
        var elCloser = elShowhideButton.children('.showHideButtonIcon');
        var showhidePanel = elShowhideButton.data('haspanel');
        var elShowhidePanel = $('#'+showhidePanel);
        elShowhidePanel.slideToggle( function(e){
            if ($(this).is(':visible')){
//                    elCloser.html('&minus;');
                    elCloser.html('hide');
            }
            else {
//                    elCloser.html('&plus;');
                    elCloser.html('show');
            }
        });
    });
    
    // add listener to manage a user clicking a link to leave the page
    $('a').click( function(event) {
        var retval = manageLeave($(this));
        return retval;
    });		

    // add listener to disable all action buttons when any action button is 
    // clicked, to prevent double clicks
    $(document).on( "click", ".addAccount", function(event) {
        if (actionsEnabled) {
            disableActions();
        } else {
            event.preventDefault();
        }
    });
    
    
    
    


    
    // define methods
    var hasForm = function() {
        if (typeof ocaForm === "undefined") {
            return false;
        } else {
            return true;
        }
    };
    var getForm = function() {
        return ocaForm;
    };
    var hasModal = function() {
        if (typeof ocaModal === "undefined") {
            return false;
        } else {
            return true;
        }
    };
    var getModal = function() {
        return ocaModal;
    };
    var getErrorHandler = function() {
        return ocaErrorHandler;
    };
    var enableActions = function() {
        $('.ocaAction').removeClass('disabled');
        actionsEnabled = true;
    };
    var disableActions = function() {
        $('.ocaAction').addClass('disabled');
        actionsEnabled = false;
    };
    /*
     * manageLeave: if the user has changed any fields on the page, check they
     * really do want to leave without updating
     * @param {object} link          the link that was clicked
     */
    var manageLeave = function(link) {
        var leave;
        
        // if the modal is visible but unchanged, close it
        if (hasModal() && ocaModal.isVisible() && !ocaModal.hasChanged()) {
            ocaModal.close();
        }
        // modal will now only be visible if any modal fields have changes
        if (hasModal() && ocaModal.isVisible()) {
            leave = ocaModal.manageLeave(link);
            
        } else if (hasForm() && ocaForm.hasChanged()) {
            leave = ocaForm.manageLeave();
            
        } else {
            leave = true;
        }
        if (leave) {
            // if there's an href, navigate.
            if (link.attr('href') && link.attr('href') !== '#') {
                location.href = link.attr('href');
            }
            return true;
        } else {
            enableActions();
            return false;
        }
    };
    var displaySuccessMsg = function(message) {
        ocaErrorHandler.hidePageMsgs();
        ocaErrorHandler.displayPageSuccessMsg(message);
    };
    var displayWarningMsg = function(message) {
        ocaErrorHandler.hidePageMsgs();
        ocaErrorHandler.displayPageWarningMsg(message);
    };
    var displayErrorMsg = function(message) {
        ocaErrorHandler.hidePageMsgs();
        ocaErrorHandler.displayPageErrorMsg(message);
    };
    var displayMsgs = function(msgs) {
        ocaErrorHandler.hidePageMsgs();
        if (msgs.success && msgs.success != '') {
            ocaErrorHandler.displayPageSuccessMsg(msgs.success);
        }
        if (msgs.warning && msgs.warning != '') {
            ocaErrorHandler.displayPageWarningMsg(msgs.warning);
        }
        if (msgs.error && msgs.error != '') {
            ocaErrorHandler.displayPageErrorMsg(msgs.error);
        }
    };
    // public api
    return {
        hasForm: hasForm,
        getForm: getForm,
        hasModal: hasModal,
        getModal: getModal,
        getErrorHandler: getErrorHandler,
        enableActions: enableActions,
        displaySuccessMsg: displaySuccessMsg,
        displayWarningMsg: displayWarningMsg,
        displayErrorMsg: displayErrorMsg,
        displayMsgs: displayMsgs
    };
};	// end of ocaPage

/**
 * ocaForm manages the Main Form in a page.
 * 
 * @param {object} errorHandler   an ocaErrorHandler
 * @param {object} htmlGenerator  an ocaHtml generator
 */
OCA7559AC.COMMON.ocaForm = function (errorHandler, htmlGenerator) {
    // initialise the object
    var ocaErrorHandler = errorHandler;
    var ocaHtml = htmlGenerator;
    var changed;  // true if any change has been made to main form fields
    init();
    
    // add listener for changes to main form fields
    $(document).on('change', '.ocaMainForm .flagchange', function () {
        setChanged();
    });		
    
    // define methods
    function addHiddenInputField(name, id, value) {
        $('.ocaMainForm').append(ocaHtml.generateHiddenInputField(name, id, value));
    }
    function displayErrors(errors, msgs) {
        ocaErrorHandler.clearFieldErrors();
        ocaErrorHandler.displayFieldErrors(errors);
        ocaErrorHandler.hidePageMsgs();
        ocaErrorHandler.displayPageMsgs(msgs);
        ocaErrorHandler.scrollToPageErrorMessage();
    }
    var hasChanged = function() {
        return changed;
    };
    function init() {
        changed = false;
    }
    /*
     * manageLeave: if the user has changed any fields in the main form,
     * check they really do want to leave without updating
     * 
     * @param {object} link          the link that was clicked
     */
    function manageLeave() {
        var checkSaveMsg = $('#ocaCancelWithoutSavingMsg').html();
        var leave;
        
        if (hasChanged()) {
            if ( confirm(checkSaveMsg) ) {
                leave = true;
            } else {
                leave = false;
                $('#mainsubmit').addClass('alert');
            }
        }
        return leave;
    }
    function setChanged() {
        changed = true;
    }
    // public api
    return {
        addHiddenInputField: addHiddenInputField,
        displayErrors: displayErrors,
        hasChanged: hasChanged,
        init:init,
        manageLeave: manageLeave,
        setChanged: setChanged
    };
};	// end of ocaForm

/**
 * ocaModal manages a Modal Pop-up Form.
 * 
 * @param {object} errorHandler   an ocaErrorHandler
 * @param {object} htmlGenerator  an ocaHtml generator
 */
OCA7559AC.COMMON.ocaModal = function (errorHandler, htmlGenerator) {
    // initialise the object
    var ocaErrorHandler = errorHandler;
    var ocaHtml = htmlGenerator;
    var changed;  // true if any change has been made to modal popup form fields
    var modalForm = $('#ocaModalPopupForm');
    init();
    
    // add listener for changes to modal popup form fields
    $(document).on('change', '#ocaModalPopupForm .flagchange', function () {
        setChanged();
    });		
    
    // add listener to remove alert from submit button when clicked
    $(document).on( "click", ".ocaModalSubmit", function(event) {
        $('#ocaModalPopupFormSubmit').removeClass('alert');
    });		
    
    
    // define methods
    var hasChanged = function() {
        return changed;
    };
    function setChanged() {
        changed = true;
    }
    function init() {
        changed = false;
        emptyModal();
    }
    function addHeader(header) {
        $('#ocaModalTitle').append(header);
    }
    function addContent(content) {
        $('#ocaModalPopupFormContent').append(content);
    }
    function addHiddenInputField(name, id, value) {
        $('#ocaModalPopupFormContent').append(ocaHtml.generateHiddenInputField(name, id, value));
    }
    function emptyModal() {
        $('#ocaModalTitle').empty();
        $('#ocaModalPopupFormContent').empty();
        $('#ocaModalPopupFormSubmit').removeClass('alert');
        ocaErrorHandler.hideModalMsgs();
    }
    function openModal() {
        $('#ocaModalPopupWindow').foundation('reveal', 'open');
    }
    function close() {
        init();
        $('#ocaModalPopupWindow').foundation('reveal', 'close');
    }
    function isVisible() {
        if ( $('#ocaModalPopupWindow').length && $('#ocaModalPopupWindow').is(':visible') ) {
            return true;
        }
        else {
            return false;
        }
    }
    /*
     * manageLeave: if the user has changed any fields in the modal popup form,
     * check they really do want to leave without updating
     * 
     * @param {object} link          the link that was clicked
     */
    function manageLeave(link) {
        var checkSaveMsg = $('#ocaCancelWithoutSavingMsg').html();
        var leave;
        if (isVisible() && hasChanged()) {
            if ( confirm(checkSaveMsg) ) {
                close();
                if (link.hasClass('close-reveal-modal') ) {
                    // just closing pop-up, not leaving page
                    leave = false;
                } else {
                    leave = true;
                }
            } else {
                leave = false;
                $('#ocaModalPopupFormSubmit').addClass('alert');
            }
        } else {
            leave = true;
        }
        return leave;
    }
    function displayErrors(errors, msgs) {
        ocaErrorHandler.clearFieldErrors();
        ocaErrorHandler.displayFieldErrors(errors);
        ocaErrorHandler.hideModalMsgs();
        ocaErrorHandler.displayModalMsgs(msgs);
        scrollToModalErrorMessage();
    }
    function scrollToModalErrorMessage() {
        $("html, body").scrollTop($('#alertBoxErrorModal').offset().top); 
    }
    
    function makeIdFromName(fieldname) {
        var id = fieldname.replace("[", "");
        id = id.replace("]", "");
        return id;
    }
    
    function generateHtmlFromFieldDef(fieldsetsDef) {
        
        var $html = $();
        // generate some default foundation elements for label, input and error cols
        var $fnRow = ocaHtml.generateFoundationRow();
        var $fnLabelCol = ocaHtml.generateFoundationColumn("small-12 medium-2 large-2");
        var $fnFieldCol = ocaHtml.generateFoundationColumn("small-12 medium-5 large-5");
        var $fnErrorCol = ocaHtml.generateFoundationColumn("small-12 medium-5 large-5");
        
        $.each(fieldsetsDef, function(seq, fieldsetDef){
            
//            console.log(fieldsetDef);
        
            var $fieldsetFields = $();
            var $fieldset;

            if (Object.keys(fieldsetDef.fields).length) {
                $.each(fieldsetDef.fields, function(seq, def){
                    
                    // set value if it's not in the definition
                    if (! def.hasOwnProperty('value')) {
                        def.value = '';
                    }
                    
                    // parse the ID to remove []
                    if (! def.hasOwnProperty('id') ) {
                        def.id = makeIdFromName(def.fldname);
                    }
                    
                    if (def.hasOwnProperty("datatype")){
                        
                        // handle hidden fields separately, no need for styling cruft
                        if (def.datatype.type !== 'hidden') {

                            var $thisFieldRow = $fnRow.clone();

                            var $labelCol = $().add($fnLabelCol.clone());
                            var $inputCol = $().add($fnFieldCol.clone());
                            var $errorCol = $().add($fnErrorCol.clone());

                            // Append empty error holder
                            $errorCol.attr("id", def.id + "_error");

                            // Field attributes. Needs to be much more generic, but waiting until spec is nailed down.
                            var attr = {};
                            var attrData = {};

                            // Setting an element attribute with name 'maxlength' 
                            // if it's in the datatype object. 
                            // Not sure of the merit in this, yet.
                            if (def.datatype.hasOwnProperty("maxlength")) {
                                attr.maxlength = def.datatype.maxlength;
                            }


                            // data-* attributes
                            // If there's a key of 'data' in the field definition, 
                            // speficially set these as a data-* attributes further down the line.

                            if (def.hasOwnProperty("data")) {
                                // console.log(def.rules);
                                attrData = def.data;
                            }

                            $labelCol.append(ocaHtml.generateLabel(def.id, "", def.label, def.reqd));
                            switch(def.datatype.type) {
                                case 'text':
                                    // text input
                                    $inputCol = $inputCol.append(
                                            ocaHtml.generateTextInputField(
                                                    def.fldname, def.id, "", def.value, def.reqd, attr, attrData
                                                    )
                                            );
                                    break;
                                case 'phone':
                                    // phone input
                                    $inputCol = $inputCol.append(
                                            ocaHtml.generatePhoneInputField(
                                                def.fldname, def.id, "", def.value, def.reqd, attr, attrData
                                                )
                                            );
                                    $errorCol.append( $('<div id="Phone_Errors_error"></div>'));
                                    break;
                                case 'email':
                                    // email input
                                    $inputCol = $inputCol.append(
                                            ocaHtml.generateEmailInputField(
                                                def.fldname, def.id, "", def.value, def.reqd, attr, attrData
                                                )
                                            );
                                    $errorCol.append( $('<div id="Email_Address_Errors_error"></div>'));
                                    break;
                                case 'radio':
                                    // radio input
                                    $inputCol = $inputCol.append(
                                            ocaHtml.generateRadioInputField(
                                                def.fldname, def.id, "", def.value, def.reqd, def.datatype.options, attrData
                                                )
                                            );
                                    break;
                                case 'dob':
                                    // dob input
                                    $inputCol = $inputCol.append(
                                            ocaHtml.generateDobInputField(
                                                def.fldname, def.id, def.value, def.reqd, attrData
                                                )
                                            );
                                    $errorCol.append( $('<input type="hidden" value="0" name="dobber" id="dobber"/><span id="agecalc"></span>'));                                
                                    break;
                                default: 
                                    console.log("Unhandled field type: " + def.datatype.type);
                            }


        //                    Add things to the Foundation row
                            $thisFieldRow.append($labelCol);
                            $thisFieldRow.append($inputCol);
                            $thisFieldRow.append($errorCol);

                            $fieldsetFields = $fieldsetFields.add($thisFieldRow);
                        
                        } else {
                            $fieldsetFields = $fieldsetFields.add(ocaHtml.generateHiddenInputField(def.fldname, def.fldname, def.value));
                        }
                    }
                }); 
            }
            
            // Wrap the fields in a fieldset if necessary
            if (fieldsetDef.fieldset.display === true) {
                $fieldset = $('<fieldset></fieldset>');
                $fieldset.append('<legend>' + fieldsetDef.fieldset.legend + '</legend>');
                $fieldset.append($fieldsetFields);
            } else {
                $fieldset = $fieldsetFields;
            }
            
            $html = $html.add($fieldset);
            
        });
        
        return $html;
    }
    
    /**
     * Gets the jQuery element representing the form in the modal
     * @returns {jQuery|$|@pro;window@pro;$|Window.$|Element}
     */
    function getModalForm(){
        return modalForm;
    }
    // public api
    return {
        hasChanged: hasChanged,
        setChanged: setChanged,
        init:init,
        addHeader:addHeader,
        addContent:addContent,
        addHiddenInputField:addHiddenInputField,
        openModal:openModal,
        close:close,
        isVisible:isVisible,
        manageLeave: manageLeave,
        displayErrors: displayErrors,
        generateHtmlFromFieldDef: generateHtmlFromFieldDef,
        getModalForm: getModalForm
    };
};	// end of ocaModal




// 
//
// Add functions for working with datatables (not sure this should be in here,
// but retained for backward compatibility)
//
//
		
/**
 * Generates a filename used when exporting datatables data
 * 
 * @param   {String} prefix    Short description of the data
 * @returns {String} filename
 */
OCA7559AC.COMMON.makeDTExportFilename = function (prefix) {
    var dtNow = Date.today().setTimeToNow();
    var dateString = ' as of ' + dtNow.toString('ddMMMyy');
    var timeString = ' at ' + dtNow.toString('HHmm') + 'hrs';
    var filename = prefix + dateString + timeString + '.csv';
    return filename;
};  // end of makeDTExportFilename




/**
 * ocaHtml generates commonly used html.
 */
OCA7559AC.COMMON.ocaHtml = function () {
    
    /**
     * Generates an action button of the form:
     * <a href="{url}" class="{classes} ocaAction button" title="{title}">{text}</a>
     * @param   {string}  url  
     * @param   {string}  classes  for customising button (e.g. 'tiny alert')  
     * @param   {string}  title    explaining what happens if you click the button  
     * @param   {string}  text     displayed name for the button  
     * @returns {Element} button   html element for displaying an action button
     */
    function generateActionButton(url, classes, title, text) {
        var buttonClasses = (classes.length = 0) ? 'ocaAction button' : classes + ' ocaAction button';
        return $('<a href="' + url + '" class="' + buttonClasses + '" title="' + title + '">' + text + '</a>');
    }
    
    /**
     * @param   {array}   buttons [string, ..., string] // html string for each button
     * @returns {Element}         html element for displaying a set of action buttons
     */
    function generateActionButtons(buttons) {
        var $buttonsHtml = $('<div class="actionButtons"></div>');
        if (buttons.length === 0) {
            $buttonsHtml.append('&nbsp;');
        } else {
            var first = buttons.shift();
            $buttonsHtml.append(first);
            $.each(buttons, function(idx, button){
                $buttonsHtml.append('&nbsp;');
                $buttonsHtml.append(button);
            });
             }
       return $buttonsHtml;
    }
    
    function generateHiddenInputField(name, id, value) {
        return $('<input type="hidden" name="' + name + '" id="' + id + '"' + ' value="' + value + '">');
    }
    /**
     * Base function, used to generate a generic input field of type text.
     * 
     * @param {string} name Element's name attribute
     * @param {string} id Element's ID attribute
     * @param {string} classes Element's class atribute
     * @param {string} value Element's value
     * @param {boolean} reqd Is required? Sets additional class of 'required' if true 
     * @param {object} attr Object containing any other attributes for the <input> element
     * @param {object} dataAttr Object containing data-* attributes
     * @returns {Element} <input /> element
     */
    function generateTextInputField(name, id, classes, value, reqd, attr, dataAttr) {
        var $field = $('<input type="text" class="' + classes + '" name="' + name + '" id="' + id + '"' + ' value="' + value + '">');
        if (reqd === true) {
            $field.addClass("required");
            
        }
        $.each(attr, function(name, val){
            $field.attr(name, val);
        });
                
        attachDataAttributes($field, dataAttr);

        return $field;
    }
    
    
    /**
     * Generates a <select> combo box field
     * 
     * @param {string} name
     * @param {string} id
     * @param {string} classes
     * @param {Object} options
     * @param {string} value
     * @param {boolean} reqd
     * @param {Object} attr
     * @param {Object} dataAttr
     * @return {Element} jQuery <select><option/>...</select> element
     */
    function generateSelectField(name, id, classes, options, value, reqd, attr, dataAttr) {
        var $field = $('<select class="' + classes + '" name="' + name + '" id="' + id + '"></select>');
        if (reqd === true) {
            $field.addClass("required");
            
        }
        $.each(attr, function(name, val){
            $field.attr(name, val);
        });
        
        if (dataAttr) {
            attachDataAttributes($field, dataAttr);
        }
        // add the options
        var keys = Object.keys(options);
        keys.forEach(function (key) {
            var $option = $('<option value="' +  options[key] + '">' + key + '</option>');
            if ( (value === options[key]) ) {
                $option.prop("selected",true);
            }
            $field.append($option);
        });
        
//        $.each( options, function( value, name ) {
//            var $option = $('<option value="' + value + '">' + name + '</option>');
//            $field.append($option);
//        });
        return $field;
    }
    
    /**
     * Generates an email input field
     * 
     * @param {type} name Element's name attribute
     * @param {type} id Element's ID attribute
     * @param {type} classes Element's class atribute
     * @param {type} value Element's value
     * @param {type} reqd Is required? Sets additional class of 'required' if true 
     * @param {type} attr Object containing any other attributes for the <input> element
     * @param {type} dataAttr Object containing data-* attributes
     * @returns {Element} <input /> element
     */
    function generateEmailInputField(name, id, classes, value, reqd, attr, dataAttr) {
        var $field = generateTextInputField(name, id, classes, value, reqd, attr, dataAttr);
        $field.addClass("email");
        attachDataAttributes($field, dataAttr);
        return $field;
    }
    
    /*
     * Generates a phone number input field
     * 
     * @param {type} name Element's name attribute
     * @param {type} id Element's ID attribute
     * @param {type} classes Element's class atribute
     * @param {type} value Element's value
     * @param {type} reqd Is required? Sets additional class of 'required' if true 
     * @param {type} attr Object containing any other attributes for the <input> element
     * @param {type} dataAttr Object containing data-* attributes
     * @returns {Element} <input /> element
     */
    function generatePhoneInputField(name, id, classes, value, reqd, attr, dataAttr) {
        var $field = generateTextInputField(name, id, classes, value, reqd, attr, dataAttr);
        $field.addClass("phone");
        attachDataAttributes($field, dataAttr);
        return $field;
    }
    
    /**
     * Generates a set of radio button input options wrapped in <label> tags
     * 
     * @param {type} name Element's name attribute
     * @param {type} id Element's ID attribute
     * @param {type} classes Element's class atribute
     * @param {type} value Element's value
     * @param {type} reqd Is required? Sets additional class of 'required' if true 
     * @param {type} attr Object containing any other attributes for the <input> element
     * @param {type} dataAttr Object containing data-* attributes
     * @returns {Element} As many <label><input/><label> options as required
     */
    function generateRadioInputField(name, id, classes, value, reqd, options, dataAttr) {
        var $html = $();
        $.each(options, function(idx, option){
            var $radioOption = $('<input type="radio" name="' + name + '" id="' + id + '_' + option.id +'" value="'+ option.id +'" class="' + classes + '">');
            var $radioOptionOuter = $('<label for="' + id + '_' + option.id +'" class="small-6 columns"></label>');

            if (reqd === true) {
                $radioOption.addClass("required");
            }
            if (value === option.id) {
                $radioOption.prop("checked",true);
            }
            attachDataAttributes($radioOption, dataAttr);
            $radioOptionOuter.append($radioOption);
            $radioOptionOuter.append(option.label);
            
            $html = $html.add($radioOptionOuter);
        });
        
        return $html;
    }
    
    /**
     * Returns a value/name object for months of the year
     * @return {OCA7559AC.COMMON.ocaHtml.getMonthOptions.ocaCommonAnonym$7}
     */
    function getMonthOptions(){
//        return {
//            "" : "--",
//            "1" : "January",
//            "2" : "February",
//            "3" : "March",
//            "4" : "April",
//            "5" : "May",
//            "6" : "June",
//            "7" : "July",
//            "8" : "August",
//            "9" : "September",
//            "10" : "October",
//            "11" : "November",
//            "12" : "December"
//        };

        // do it this way round so that order is preseved. JS will re-order anything
        // that has an integer (or integer-like) key!
        return {
            "--" : "",
            "January" : "1",
            "February" : "2",
            "March" : "3",
            "April" : "4",
            "May" : "5",
            "June" : "6",
            "July" : "7",
            "August" : "8",
            "September" : "9",
            "October" : "10",
            "November" : "11",
            "December" : "12"
        };        
    }
    
    /**
     * Generates a complete Foundation row containing dd mm and yyyy input fields
     * 
     * @param {type} name Element's name attribute
     * @param {type} id Element's ID attribute
     * @param {type} value Element's value
     * @param {type} reqd Is required? Sets additional class of 'required' if true 
     * @param {type} dataAttr Object containing data-* attributes
     * @returns {Element} Foundation row element <div></div>
     */
    function generateDobInputField(name, id, value, reqd, dataAttr){
        var monthOptions = getMonthOptions();
        var $ddField = generateTextInputField(name + '-d', id + '-d', 'dob-d flagchange', value, false, {"placeholder":"dd", "maxlength":2}, null);
        // var $mmField = generateTextInputField(name + '-m', id + '-m', 'dob-m flagchange', value, false, {"placeholder":"mm", "maxlength":2}, null);
        var $mmField = generateSelectField(name + '-m', id + '-m', 'dob-m flagchange', monthOptions, value, false, {"placeholder":"Month"}, null);
        var $yyField = generateTextInputField(name + '-y', id + '-y', 'dob-y flagchange', value, false, {"placeholder":"yyyy", "maxlength":4}, null);
        
        var $ddCol = generateFoundationColumn('small-3 large-2'); 
        var $mmCol = generateFoundationColumn('small-5 large-7');
        var $yyCol = generateFoundationColumn('small-4 large-3');
        
        $ddCol.append($ddField);
        $mmCol.append($mmField);
        $yyCol.append($yyField);
        
        var $html = $('<div class="row"></div>');
        
        $html.append($ddCol);
        $html.append($mmCol);
        $html.append($yyCol);

        return $html;
    }
    
    /**
     * 
     * @param {string} forId for For attribute
     * @param {string} classes Label classes
     * @param {string} label Label text
     * @param {boolean} reqd Is required? Adds an asterisk to the label text if true.
     * @returns {Element} <label />
     */
    function generateLabel(forId, classes, label, reqd) {
        var $label = $('<label for="' + forId +'" class="' + classes + '">' + label + '</label>');
        if (reqd === true) {
            $label.append($('<span class="asterisk">&nbsp;*</span>'));
        }
        return $label;
    }
    
    /**
     * Generates a Foundation 5 "row" fragment
     * 
     * @returns {Element} <div />
     */
    function generateFoundationRow() {
        return $('<div class="row"></div>');
    }
    
    /**
     * Generates a Foundation 5 "column" element
     * @param {string} classes Classes to add which dictate the sizes, e.g "small-12 medium-5"
     * @returns {Element} <div />
     */
    function generateFoundationColumn(classes) {
        return $('<div class="'  + classes + ' columns"></div>');
    }
    
   /**
    * attaches data-* attributes with values to the field (param by reference)
    * 
    * @param {type} $field The jQuery element to attach the data-* attrs to
    * @param {type} dataAttrs Object containing the values
    */
    function attachDataAttributes($field, dataAttrs) {
        if (dataAttrs !== null && Object.keys(dataAttrs).length) {
            $.each(dataAttrs, function(dataAttr, value){;
               $field.data(dataAttr, value);
               // console.log("Doing " + dataAttr);
            });
        }
    }
    
    /**
     * Generates a Foundation 5 alert box
     * 
     * @param {string} id          element ID
     * @param {string} style       Choice of 'info, alert, success, warning, secondary'.
     * @param {bool}   allowClose  true if user is allowed to close the alert box
     * @returns {Element}          jQuery element <div/>
     */
    function generateFoundationAlertbox(id, style, allowClose) {
        if (allowClose === true) {
            var close = '<a href="#" class="close">&times;</a>';
        } else {
            var close = '';
        }
        var $box = $('<div data-alert class="alert-box ' + style + '" id="' + id + '">' + close + '</div>');
        return $box;
    }
    
    // public api
    return {
        generateActionButton: generateActionButton,
        generateActionButtons: generateActionButtons,
        generateHiddenInputField: generateHiddenInputField,
        generateTextInputField: generateTextInputField,
        generateEmailInputField: generateEmailInputField,
        generatePhoneInputField: generatePhoneInputField,
        generateDobInputField: generateDobInputField,
        generateRadioInputField: generateRadioInputField,
        generateSelectField: generateSelectField,
        generateFoundationAlertbox: generateFoundationAlertbox,
        generateFoundationColumn: generateFoundationColumn,
        generateFoundationRow: generateFoundationRow,
        generateLabel: generateLabel,
        getMonthOptions : getMonthOptions
    };
};	// end of ocaHtml


/** 
 * Some custom field validation functions
 * 
 * @param {type} ocaDateFunctions
 * @returns {OCA7559AC.COMMON.fieldValidator.ocaCommonAnonym$12}
 */
OCA7559AC.COMMON.fieldValidator = function (ocaDateFunctions) {
    // var common = OCA7559AC.COMMON;
    
    
    /**
     * Validates a date of birth against given rules.
     * 
     * @param {int} d day dd
     * @param {int} m month mm
     * @param {int} y year yyyy
     * @param {object} validateAge
     * @returns {Boolean}
     */
    function validPersonDoB(d,m,y,validateAge) {
        var dob = ocaDateFunctions.makeDateFromParts( y, m, d );
        // console.info("Validate dob: " + dob.format('YYYY-MM-DD') + ', Min: ' + validateAge.ageFrom + ', Max: ' + validateAge.ageTo + ', At: ' + validateAge.checkDate)
        if (dob !== false && dob.isBefore(moment())) {
            var inRange = ocaDateFunctions.inAgeRangeAtDate(dob, validateAge.ageFrom, validateAge.ageTo, validateAge.checkDate);
            if (inRange) {
                return "valid";
            } else {
                // set error
                return "out-of-range";
            }
        } else {
            return "invalid";
        }
        
    }
    
    function formatDoBRangeErrors(validateAge) {
        var message = "";
        if (validateAge.ageFrom && validateAge.ageTo) {
            message = "between " + validateAge.ageFrom + " and " + validateAge.ageTo;
        }
        
        if (validateAge.ageFrom && !validateAge.ageTo) {
            message = "at least " + validateAge.ageFrom;
        }
        
        if (validateAge.ageTo && !validateAge.ageFrom) {
            message = "no older than " + validateAge.ageTo;
        }
        
        
        if (message && validateAge.checkDate) {
            message = message  + " on " + validateAge.checkDate;
        }
        
        return message;
        
    }


    return {
        formatDoBRangeErrors: formatDoBRangeErrors,
        validPersonDoB: validPersonDoB
    }
};  // end of fieldValidator


/** Sticky Messages
 *  Set of functions used to make alert box messages 'sticky' when scrolled out of viewport
 * 
 * 
 * @returns {OCA7559AC.COMMON.stickyMessages.ocaCommonAnonym$15}
 */
OCA7559AC.COMMON.stickyMessages = function () {

    // 
    //
    // functions for managing sticky messages
    //
    //


    /**
     * isStickyMsgVisible determines whether the given element is visible in the
     * viewport (for making messages sticky)
     * 
     * @param   {object}  elem jQuery element
     * @returns {Boolean}
     * 
     * @author  Andrew Wallace
     * @version 30 Sep 2017
     */
    function isStickyMsgVisible(elem) {
        var offset = $('.top-bar').outerHeight();

        var docViewTop = $(window).scrollTop() + offset;
        var docViewBottom = docViewTop + $(window).height();

        var elemTop = $(elem).offset().top;
        var elemBottom = elemTop + $(elem).height();

        return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    };  // end of isStickyMsgVisible

    /** 
     * offsetStickyMsgsBox shifts the 'sticky' container away from the top bar.
     * Should probably also be done on window.resize
     * 
     * @param {object} container jQuery element
     * 
     * @author  Andrew Wallace
     * @version 30 Sep 2017
     */
    function offsetStickyMsgsBox(container) {
        var offset = $('.top-bar').outerHeight();
        // if it's currently sitting at the very top of the window, 
        // move it beneath the top-bar
        if ( container.position().top === 0 ) {
            container.css({'top': offset + 'px'});
        }    
    };  // end of offsetStickyMsgsBox

    /** 
     * addStickyBoxClose adds a cross to the right of the alert box so that it can
     * be closed when sticky
     * 
     * @author  Andrew Wallace
     * @version 30 Sep 2017
     */
    function addStickyBoxClose() {
        $('.stickyMessages.fixed .alert-box').each(function(i,v){
                // append a closer
            if ( $(this).children('a.close').length < 1 ) {
                    $(this).append('<a href="#" class="close">&times;</a>');
            }
            });
    };  // end of addStickyBoxClose

    /**
     * activateStickyMsgs monitors page scrolling and the position of the
     * pageMessages div. If it is out of view, or scrolls out of view, it becomes
     * 'sticky'. Its position becomes fixed, adjusted for the height of the top-bar.
     */
     function activateStickyMsgs () {       
        $('.pageMessages').each(function () {
            var $stickyMessages = $('.stickyMessages');
            if (isStickyMsgVisible(this) !== true) {
                $stickyMessages.addClass('fixed');
                $(this).css({'padding-top':'55px'});
                offsetStickyMsgsBox($stickyMessages);
                addStickyBoxClose();
                // fixAlertBox();
            } else {
                $stickyMessages.removeClass('fixed');
                $stickyMessages.css({'top':''});
                $(this).css({'padding-top':'0'});
            }
        });
    };  // end of activateStickyMsgs    
    
    return {
        activateStickyMsgs: activateStickyMsgs
    }
    
}; 

OCA7559AC.COMMON.generateDTExportFilename = function(prefix) {
    var dtNow = Date.today().setTimeToNow();

    var filename = prefix + ' as of ' + dtNow.toString('ddMMMyy') + ' at ' + dtNow.toString('HHmm') + 'hrs';

    return filename;
}


OCA7559AC.COMMON.dobval = function () {
    
    var common = OCA7559AC.COMMON
    
    var dateFns = common.ocaDateFunctions();
    var fieldValidator = common.fieldValidator(dateFns);
    var errorHandler = common.ocaErrorHandler("dobval");

    function validateAgeOnSubmit(form) {
//        console.log("Running validator");
        var ageError, errors = [];

        var agerules = $("#dob-y").data("agerules");
        if (!agerules.length) {
            agerules = {
                ageFrom:null,
                ageTo:null,
                checkDate:null
            };
        }
        
        var validDob = fieldValidator.validPersonDoB( $('#dob-d').val(),$('#dob-m').val(),$('#dob-y').val(), agerules);

        if (validDob !== 'valid') {
            if (validDob === 'invalid') {
                errors['dob'] = 'Please enter a valid DoB';
            } else if (validDob === 'out-of-range') {
                errors['dob'] = 'Must be ' + fieldValidator.formatDoBRangeErrors(agerules);
            }
            // spit out errors somewhere
            console.log(errors['dob']);
            return false;
        } else {
//            console.log("Valid DoB");
//            return false;
            form.submit();
        }

    }    
    
    var init_jqValidate = $(".dobform").validate({
        "rules": {
            "dobber": {
                required: true,
                validThreepartDoB:[ "#dob-y",  "#dob-m",  "#dob-d", "#dobber" ]
            }
        },
        "messages": {
            "dobber": { 
                required: 'Please enter your date of birth.', 
                validThreepartDoB: 'Please enter a valid date of birth'
            }
        },
        "errorElement": "small",
        "submitHandler": validateAgeOnSubmit,
        "errorPlacement": function(error, element) {
            if (element.attr("type") == "radio" || element.attr("type") == "checkbox"){
                error.appendTo( element.parent().parent() );
            }
            else{
                error.insertAfter(element);
            }
        },
        "ignore": "",
        "invalidHandler": function(event, validator) {
            var errors = validator.numberOfInvalids();
            if (errors) {
                errorHandler.displayPageErrorMsg("Please correct the highlighted fields");
                errorHandler.scrollToPageErrorMessage();
            }
        }
    });
}
    
//
//
// add a readyFn to initialise common javascript listeners and objects etc
//
//
		
/**
 * readyFn initialise common javascript listeners and objects etc
 */
OCA7559AC.COMMON.readyFn = function () {
    var common = OCA7559AC.COMMON;
    
    // for browsers which have no console (old IE, etc)
    if(typeof console === "undefined") {
        console = {
            log: function() { },
            debug: function() { }
        };
    }

    // initialise foundation detector
    $(document).foundation({
        reveal: {
            on_scroll: false
        }
    });

    // JS open in new window handler
    $('body').on('click', '.newWindow, .newWindowNoIcon', function() {
        window.open( $(this).attr('href') );
        return false;        
    });
    
    // activate sticky messages when page is displayed, and add a listener to
    // the scroll event
    var stickyMessages = new common.stickyMessages();
    stickyMessages.activateStickyMsgs();
    
    $(window).scroll(function () {
        stickyMessages.activateStickyMsgs();
    });
    
    // make the help button sticky
    if ($('#reveal-help').length > 0) {
        var offset = $('.top-bar').height();
        $('#reveal-help').stick_in_parent({offset_top: offset + 15});
    }
    
    /**
     * Add click event to elements with a data-showhide attribute
     * 
     * data-showhide is of the format 
     * {
     *   target: id of the target element to show/hide
     *   hidden: text for the toggle when target hidden
     *   shown: text for the toggle when target shown
     * }
     * 
     */
    $(document).on('click', '[data-showhide]', function(e){
        e.preventDefault();
        var showHideConfig = $(this).data('showhide');
        if ($(this).data('showhide')) {
            var elTarget = $('#' + showHideConfig.target);
            var toggleTextWhenHidden = showHideConfig.hidden;
            var toggleTextWhenShown = showHideConfig.shown;
            common.showHider( $(this), toggleTextWhenHidden, toggleTextWhenShown, elTarget );
        } else {
            return false;
        }
    });    
    
};  // end of readyFn
    
